<template>
  <div>
    <!-- Title -->
    <h3
      v-t="formSuccess === false ? 'login.forgot_password_title1' : 'login.forgot_password_title2'"
      class="mt-4 mb-2"
    />
    <!-- Description -->
    <p v-t="formSuccess === false ? 'login.forgot_password_desc1' : 'login.forgot_password_desc2'" />
    <!-- Form -->
    <v-form
      v-if="formSuccess === false"
      ref="form-forgot-password"
      class="mb-8"
      @submit.prevent="onSubmit"
    >
      <label
        v-t="'login.email'"
        for="field-email"
        class="label-input"
      />
      <v-text-field
        id="field-email"
        v-model="email"
        :rules="[rules.required, rules.email]"
        hide-details="auto"
        color="primary"
        class="mb-4"
        name="email"
        outlined
        dense
      />
      <!-- Action -->
      <div class="d-flex align-center justify-space-between">
        <router-link
          to="/login"
          class="text-decoration-none"
        >
          <v-icon
            color="primary"
            v-text="'chevron_left'"
          />
          {{ $t(`${$vuetify.breakpoint.smAndUp === true ? 'login.back_to_login' : 'login.back'}`) }}
        </router-link>
        <v-btn
          color="primary"
          type="submit"
          :loading="isLoading"
        >
          {{ $t('login.submit') }}
        </v-btn>
      </div>
    </v-form>
    <router-link
      v-else
      to="/login"
      class="text-decoration-none"
    >
      <v-icon
        color="primary"
        v-text="'chevron_left'"
      />
      {{ $t('login.back_to_login') }}
    </router-link>
  </div>
</template>

<script>
import jwt from 'jsonwebtoken';

import login from '../../mixins/login';

export default {
  name: 'FormForgotPassword',
  mixins: [login],
  data () {
    return {
      formSuccess: false,
      email: '',
      isLoading: false,
    };
  },
  metaInfo () {
    return {
      title: `${this.$t('login.forgot_password')} | `,
    };
  },
  methods: {
    /**
     * Handle submit action + email API
     *
     * @returns {Void}
     */
    async onSubmit () {
      const { email } = this;
      const validation = this.$refs['form-forgot-password'].validate();

      if (validation === true) {
        this.isLoading = true;
        let token = null;
        let name = null;
        try {
          const { apiRoot } = this.$xms.config;
          const postData = {
            email,
          };
          const response = await this.$http.post(`${apiRoot}/forgot-password`, postData);
          token = response.data.token;
          name = response.data.name;
        } catch (e) {
          // If address inputed does not exist in database
          this.$reportError({ message: 'errors.forgot_password_error' });
        }
        if (token !== null) {
          const origin = window.location.origin;
          const jwtData = {
            email
          };
          const logoEmail = this.$xms.theme.login.logoEmail !== undefined ? this.$xms.theme.login.logoEmail : this.$xms.theme.login.logo;
          const formData = {
            from: 'xms@ax2.ca',
            to: [
              email
            ],
            subject: this.$t('login.reset_password'),
            body: {
              logo: `${origin}${require(`./../../../project/assets/img/${logoEmail}`)}`,
              title: this.$t('login.email_forgot_password_title'),
              hello: `${this.$t('login.hi')} ${name},`,
              desc1: this.$t('login.email_forgot_password_desc1', { site: this.$t('global.site') }),
              buttonLabel: this.$t('login.reset_password'),
              adminDesc1: this.$t('login.email_forgot_password_admin_desc1'),
              adminDesc2: this.$t('login.email_forgot_password_admin_desc2'),
              troubleDesc: this.$t('login.email_forgot_password_trouble_desc'),
              site: this.$t('global.site'),
              admin: this.$xms.config.adminEmail,
              resetLink: `${origin}/reset-password?token=${token}`,
              colorAccent: this.$xms.theme.global.accent,
            },
            template: 'forgotpassword',
          };
          const tokenEmail = jwt.sign(jwtData, process.env.VUE_APP_MAIL_JWT_SECRET);
          try {
            // Call to mail API
            await this.axios.post(
              '/send',
              formData,
              {
                baseURL: process.env.VUE_APP_MAIL_URL,
                headers: { 'Authorization': `Bearer ${tokenEmail}` }
              },
            );
            this.formSuccess = true;
          } catch (e) {
            this.$reportError({ message: e.message });
          }
        }
        this.isLoading = false;
      }
    },
  },
};
</script>
