export default {
  data () {
    return {
      rules: {
        required: value => !!value || this.$t('errors.required'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('errors.email_invalid');
        },
        password: value => {
          return /^[a-zA-Z0-9]*$/.test(value)
            && value.length >= 8
            && value.length <= 20
            && value.toLowerCase() !== value
            && /\d/.test(value) || this.$t('errors.password_format');
        },
        passwordConfirmation: value => {
          return value === this.password || this.$t('errors.password_match');
        },
      },
    };
  },
};
